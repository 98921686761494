import { useEffect, useState, useRef } from 'react';
import {
    Grid,
    GridCellProps,
    GridColumn,
    GridColumnMenuFilter,
    GridColumnMenuProps,
    GridDataStateChangeEvent,
    GridExpandChangeEvent,
    GridToolbar,
} from '@progress/kendo-react-grid';
import { GridCommandCell } from '../../components/grid-command-cell.component';
import { useLoadData } from '../../hooks/use-load-data.hook';
import { Claim } from '../../models/claim/claim';
import { addOrUpdateArrayItem, deleteArrayItem } from '../../utils/array.util';
import { EditClaim } from './edit-claim.component';
import { useNavigate } from 'react-router-dom';
import {
    DeleteModelDialog,
    DeletePostDataInfo,
    DeleteStatus,
} from '../../components/delete-model-dialog.component';
import { SubmitStatus } from '../../components/formik-model-dialog.component';
import { LoadingIndicator } from '../../components/loading-indicator.component';
import { useStorage } from '../../hooks/use-storage';
import { ClaimSetupBreadCrumb } from '../../components/claim-setup-breadcrumb';
import { StorageKey } from '../../constants';
import { State, process, DataResult } from '@progress/kendo-data-query';
import { setExpandedState, setGroupIds } from '@progress/kendo-react-data-tools';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { GuardedComponent } from '../../components/guarded.componemt';
import { PermissionName } from '../../models/user-security/permission.name';

export const ClaimsPage: React.FC = () => {
    let gridPDFExport: GridPDFExport | null;
    const initialDataState: State = {
        take: 10,
        skip: 0,
        group: [],
    };

    const processWithGroups = (data: Claim[], dataState: State) => {
        const groups = dataState.group;
        dataState.group = groups;
        const newDataState = process(data, dataState);
        setGroupIds({ data: newDataState.data, group: dataState.group });
        return newDataState;
    };
    const history = useNavigate();
    const [editClaimData, setEditClaimData] = useState<Claim>();
    const [deleteClaimData, setDeleteClaimData] = useState<DeletePostDataInfo>();
    const { isLoading, result, isError, reload } = useLoadData<Claim[]>(`claims/list`);

    const [claim, setCurrentClaim] = useStorage<Claim>(StorageKey.CurrentUser, new Claim());
    const [collapsedState, setCollapsedState] = useState<string[]>([]);
    const [resultData, setResultData] = useState<DataResult>();
    const [dataState, setDataState] = useState<State>(initialDataState);
    const exportExcel = useRef<ExcelExport | null>(null);

    useEffect(() => {
        if (result) {
            setResultData(processWithGroups(result, dataState));
        }
    }, [result]);

    const onSaveComplete = (status: SubmitStatus, data: Claim) => {
        if (status === SubmitStatus.Completed) {
            // const newData = addOrUpdateArrayItem(result, data, 'claimID');
            reload();
            setResultData(processWithGroups(result, dataState));
        }
        setEditClaimData(null);
    };

    const onDeleteComplete = (status: DeleteStatus, data: Claim) => {
        if (status === DeleteStatus.Completed) {
            reload();
            setResultData(processWithGroups(result, dataState));
        }
        setDeleteClaimData(null);
    };

    const onView = (data: Claim) => {
        setCurrentClaim(data);
        history(`/buildings/${data.claimGUID}`);
    };

    const onReport = (data: Claim) => {
        setCurrentClaim(data);
        history(`/reports/observation/${data.claimGUID}`);
    };

    const onAddNew = () => {
        const claim = new Claim();
        claim.claimID = 0;
        setEditClaimData(claim);
    };

    const onEdit = (data: Claim) => {
        setEditClaimData(data);
    };

    const onRemove = (data: Claim) => {
        const { claimID } = data;
        setDeleteClaimData({
            data,
            route: `claims/delete/${claimID}`,
        });
    };

    const exportPDF = () => {
        setTimeout(() => {
            if (gridPDFExport) {
                gridPDFExport.save(result);
            }
        }, 100);
    };

    const excelExport = () => {
        if (exportExcel.current !== null) {
            exportExcel.current.save();
        }
    };

    const onSearch = (e: any) => {
        if (e.target.value.length > 0) {
            const searchKey: string = e.target.value;
            setResultData(
                processWithGroups(
                    result.filter(
                        (p) =>
                            p.buildingComplexName.toLowerCase().includes(searchKey.toLowerCase()) ||
                            p.client.toLowerCase().includes(searchKey.toLowerCase()) ||
                            p.claimNumber.toLowerCase().includes(searchKey.toLowerCase()) ||
                            p.keystoneNumber.toLowerCase().includes(searchKey.toLowerCase()),
                    ),
                    dataState,
                ),
            );
        } else {
            setResultData(processWithGroups(result, dataState));
        }
    };

    const PageTemplate = (props: any) => {
        return (
            <>
                <div className="report-header">KeyStone Windows&Doors</div>
                <div className="report-footer">
                    Page {props.pageNum} of {props.totalPages}
                </div>
            </>
        );
    };

    const CommandCell = (props: GridCellProps) => (
        <GridCommandCell
            {...props}
            view={onView}
            report={onReport}
            edit={onEdit}
            remove={onRemove}
            add={onAddNew}
            idPropertyName="claimID"
            viewPermissionName={PermissionName.ViewClaim}
            editPermissionName={PermissionName.EditClaim}
            deletePermissionName={PermissionName.DeleteClaim}
        />
    );

    const ColumnMenu = (props: GridColumnMenuProps) => {
        return (
            <div>
                <GridColumnMenuFilter {...props} />
            </div>
        );
    };

    const expandChange = (event: GridExpandChangeEvent) => {
        const item = event.dataItem;

        if (item.groupId) {
            const newCollapsedIds = !event.value
                ? [...collapsedState, item.groupId]
                : collapsedState.filter((groupId) => groupId !== item.groupId);
            setCollapsedState(newCollapsedIds);
        }
    };

    const dataStateChange = (event: GridDataStateChangeEvent) => {
        const newDataState = processWithGroups(result, event.dataState);
        setResultData(newDataState);
        setDataState(event.dataState);
    };

    let newData = null;
    if (resultData) {
        newData = setExpandedState({
            data: resultData.data,
            collapsedIds: collapsedState,
        });
    }

    let grid = null;
    if (newData) {
        grid = (
            <Grid
                data={newData}
                pageable={{ pageSizes: [10, 20, 50, 100] }}
                total={resultData.total}
                groupable={true}
                sortable={true}
                onDataStateChange={dataStateChange}
                {...dataState}
                onExpandChange={expandChange}
                expandField="expanded"
            >
                <GridToolbar>
                    <button title="Export PDF" className="k-button k-primary" onClick={exportPDF}>
                        Export PDF
                    </button>
                    <button
                        title="Export Excel"
                        className="k-button k-primary"
                        onClick={excelExport}
                    >
                        Export to Excel
                    </button>
                </GridToolbar>
                <GridColumn
                    field="buildingComplexName"
                    title="PROJECT NAME"
                    columnMenu={ColumnMenu}
                />
                <GridColumn field="claimNumber" title="CLAIM NUMBER" columnMenu={ColumnMenu} />
                <GridColumn
                    field="keystoneNumber"
                    title="KEYSTONE NUMBER"
                    columnMenu={ColumnMenu}
                />
                <GridColumn field="client" title="INSURED NAME" columnMenu={ColumnMenu} />
                <GridColumn cell={CommandCell} width="240px" groupable={true} />
            </Grid>
        );
    }

    if (isError) {
        return <div>Error</div>;
    }
    return (
        <div className="container-fluid">
            <div className="top-sec-align">
                <ClaimSetupBreadCrumb />
                <h5 className='mt-2 title-web text-uppercase'>Projects</h5>
                <input
                    type="text"
                    className="form-control ms-auto search-right-align searchBg"
                    onChange={onSearch}
                />
                <GuardedComponent permission={PermissionName.AddClaim}>
                    <button title="Add new" className="btn btn-primary ms-3" onClick={onAddNew}>
                        Add new
                    </button>
                </GuardedComponent>
            </div>
            <LoadingIndicator isLoading={isLoading} />
            <div className="card">
                {newData && (
                    <>
                        {grid}
                        <GridPDFExport
                            ref={(pdfExport) => (gridPDFExport = pdfExport)}
                            pageTemplate={PageTemplate}
                            margin="1.2cm"
                            paperSize="A4"
                            landscape={false}
                        >
                            <GridColumn field="buildingComplexName" title="PROJECT NAME" />
                            <GridColumn field="claimNumber" title="CLAIM NUMBER" />
                            <GridColumn field="keystoneNumber" title="KEYSTONE NUMBER" />
                            <GridColumn field="client" title="INSURED NAME" />
                            {grid}
                        </GridPDFExport>
                        <ExcelExport data={result} ref={exportExcel}>
                            <ExcelExportColumn
                                width={300}
                                field="buildingComplexName"
                                title="PROJECT NAME"
                            />
                            <ExcelExportColumn
                                width={150}
                                field="claimNumber"
                                title="CLAIM NUMBER"
                            />
                            <ExcelExportColumn
                                width={150}
                                field="keystoneNumber"
                                title="KEYSTONE NUMBER"
                            />
                            <ExcelExportColumn width={300} field="client" title="INSURED NAME" />
                        </ExcelExport>
                    </>
                )}
            </div>
            {editClaimData && <EditClaim data={editClaimData} onClose={onSaveComplete} />}
            {deleteClaimData && (
                <DeleteModelDialog
                    title="Delete Claim"
                    deleteData={deleteClaimData}
                    onClose={onDeleteComplete}
                >
                    <div>
                        Are you sure you want to delete claim - {deleteClaimData.data.claimNumber}?
                    </div>
                </DeleteModelDialog>
            )}
        </div>
    );
};
