import { useEffect, useState } from "react";
import { ClaimObservationMain } from "../../../models/claim/claim-observation";
import { ObservationType } from "../../../models/masters/observation-type";
import { usePostData } from "../../../hooks/use-post-data.hook";
import EditIcon from "../../../images/edit";
import CameraIcon from "../../../images/camera-icon";
import GalleryIcon from "../../../images/gallery";

interface IEntityObservationHeaderProps {
    roomID: number;
    entityID: number;
    entityType: ObservationType;
    entityName?: string;
    claimObservationMain?: ClaimObservationMain;
}

export const EntityHeader: React.FunctionComponent<IEntityObservationHeaderProps> = (props) => {
    const { entityID, entityType, entityName, roomID, claimObservationMain } = props;
    const [claimObservationMainData, setClaimObservationMainData] = useState<ClaimObservationMain>(claimObservationMain)

    const {
        submitData: claimMainSubmit,
        response: { isSubmitting: isClaimMainSubmit, result: responseClaimMain, error: errorClaimMain, submitActionType: claimSubmitType },
    } = usePostData<any>('ClaimObservation/SaveClaimObservationMain', 'POST');

    useEffect(() => {
        if (claimObservationMain) {
            setClaimObservationMainData(claimObservationMain)
        }
    }, [responseClaimMain]);

    const handleCheckChangeClaimMain = async (e: any, type: string) => {
        e.persist()
        const isEventRelated = claimObservationMainData?.isEventRelated;
        const isFlaged = claimObservationMainData?.isFlaged;
        const isCompliant = claimObservationMainData?.isCompliant;

        const claimObsMain: ClaimObservationMain = {
            claimObservationMainID: 0,
            roomID: roomID,
            entityID: entityID,
            entityType: entityType,
            entityName: entityName,
            isEventRelated: e.target?.name === "eventrelated" ? (type === "YES" ? true : false) : isEventRelated,
            isFlaged: e.target?.name === "flaged" ? (type === "YES" ? true : false) : isFlaged,
            isCompliant: e.target?.name === "compliant" ? (type === "YES" ? true : false) : isCompliant,
            createdBy: 1
        }
        await claimMainSubmit({ data: claimObsMain });
    }

    if (!isClaimMainSubmit) {
        if (responseClaimMain) {
            setClaimObservationMainData(responseClaimMain);
        }
        console.log(responseClaimMain)
    }
    return (
        <>
            <div className="row">
                <div className="d-flex justify-between">
                    <div className="col-12 mt-4">
                        <div className="row">
                            <div className="d-flex justify-between">
                                <div className="col-3">{entityName}</div>
                                {/* <div className="col d-flex justify-content-end" ><div style={{ cursor: "pointer" }}><CameraIcon></CameraIcon></div><div style={{ cursor: "pointer" }}><GalleryIcon></GalleryIcon></div></div> */}
                                <div className='col'>
                                    <div style={{ borderLeft: "1px solid gray", height: "100%" }}></div>
                                </div>
                                <div className="col">
                                    <div style={{ display: "flex", marginInlineEnd: "10px", width: "400px" }}>
                                        <div>Compliant:</div>
                                        {claimObservationMainData && claimObservationMainData?.isCompliant !== null ? (<>
                                            <div>
                                                <input name="compliant" checked={claimObservationMainData?.isCompliant != null ? claimObservationMainData?.isCompliant : null} onChange={(e) => { handleCheckChangeClaimMain(e, "YES") }} style={{ marginRight: "5px", marginLeft: "10px" }} type='radio'></input>Yes
                                            </div>
                                            <div><input name="compliant" checked={claimObservationMainData?.isCompliant !== null ? !claimObservationMainData?.isCompliant : null} onChange={(e) => { handleCheckChangeClaimMain(e, "NO") }} style={{ marginRight: "5px", marginLeft: "10px" }} type='radio'></input>No</div>
                                        </>) : (<>
                                            <div>
                                                <input name="compliant" onChange={(e) => { handleCheckChangeClaimMain(e, "YES") }} style={{ marginRight: "5px", marginLeft: "10px" }} type='radio'></input>Yes
                                            </div>
                                            <div><input name="compliant" onChange={(e) => { handleCheckChangeClaimMain(e, "NO") }} style={{ marginRight: "5px", marginLeft: "10px" }} type='radio'></input>No</div>
                                        </>)}
                                    </div>
                                </div>
                                <div className='col'>
                                    <div style={{ borderLeft: "1px solid gray", height: "100%" }}></div>
                                </div>
                                <div className="col">
                                    <div style={{ display: "flex", textAlign: 'center', width: "300px", marginInlineEnd: "20px" }}>
                                        <div>Event Related:</div>
                                        {claimObservationMainData && claimObservationMainData?.isEventRelated !== null ? (<>
                                            <div>
                                                <input name='eventrelated' checked={claimObservationMainData?.isEventRelated !== null ? claimObservationMainData?.isEventRelated : null} onChange={(e) => { handleCheckChangeClaimMain(e, "YES") }} style={{ marginRight: "5px", marginLeft: "10px" }} type='radio'></input>Yes
                                            </div>
                                            <div><input name='eventrelated' checked={claimObservationMainData?.isEventRelated !== null ? !claimObservationMainData?.isEventRelated : null} onChange={(e) => { handleCheckChangeClaimMain(e, "NO") }} style={{ marginRight: "5px", marginLeft: "10px" }} type='radio'></input>No</div>
                                        </>) : (
                                            <>
                                                <div>
                                                    <input name='eventrelated' onChange={(e) => { handleCheckChangeClaimMain(e, "YES") }} style={{ marginRight: "5px", marginLeft: "10px" }} type='radio'></input>Yes
                                                </div>
                                                <div><input name='eventrelated' onChange={(e) => { handleCheckChangeClaimMain(e, "NO") }} style={{ marginRight: "5px", marginLeft: "10px" }} type='radio'></input>No</div>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className='col' style={{ display: "none" }}>
                                    <div style={{ borderLeft: "1px solid gray", height: "100%" }}></div>
                                </div>
                                <div className="col" style={{ display: "none" }}>
                                    <div className="d-flex" style={{ display: "flaged", width: "100px" }}>
                                        <div>Flag:</div>
                                        {claimObservationMainData?.isFlaged !== null ? (<>
                                            <div>
                                                <input name="flaged" checked={claimObservationMainData?.isFlaged !== null ? claimObservationMainData?.isFlaged : null} onChange={(e) => { handleCheckChangeClaimMain(e, "YES") }} style={{ marginRight: "5px", marginLeft: "10px" }} type='radio'></input>
                                            </div>
                                        </>) : (<>
                                            <div>
                                                <input name="flaged" onChange={(e) => { handleCheckChangeClaimMain(e, "YES") }} style={{ marginRight: "5px", marginLeft: "10px" }} type='radio'></input>
                                            </div>
                                        </>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>

    );
};
