import { ClaimObservationOption } from './claim-observation-option';
import { ObservationType } from '../masters/observation-type';
import { OptionClaimValue } from '../masters/observation-option';

export class ClaimObservation {
    observationID: number;
    observationListID: number;
    entityType: ObservationType;
    entityID: number;
    isInterior: boolean;
    note: string;
    observation: string;
    options?: ClaimObservationOption[];
    isStormRelated: boolean;
    photos: Photo[];
    images: number;
    perils: string;
}
export class Photo {
    observationID: number;
    fileId: number;
    thumbCDN: string;
    fileCDN: string;
    comments: string;
    isBasePhoto: boolean;
    basePhotoID: number;

}

export class ClaimObservationMain {
    claimObservationMainID: number;
    roomID: number;
    entityID: number;
    entityName: string;
    entityType: string;
    isCompliant?: boolean;
    isEventRelated?: boolean;
    isFlaged?: boolean;
    createdBy: number;
}

// export class ClaimObservationOptionNote {
//     observationID: number;
//     observationListID: number;
//     entityType: ObservationType;
//     entityID: number;
//     optionId: number;
//     option: string;
//     value: any;
//     note: string;
// }