import { chain, filter, isEmpty, join, map } from 'lodash';
import * as Yup from 'yup';
import { FormikModelDialog, SubmitStatus } from '../../../components/formik-model-dialog.component';
import { LoadingIndicator } from '../../../components/loading-indicator.component';
import { useLoadData } from '../../../hooks/use-load-data.hook';
import { Building } from '../../../models/claim/building';
import { MasterType } from '../../../models/masters/master-type';
import { UnitRailing } from '../../../models/claim/unit-railing';
import { EditRailingForm } from './edit-railing.form';
import {
    WINDOWS_DOORS_API_URL,
    WINDOWS_DOOR_OTHER_OPTION,
    WINDOWS_DOOR_OTHER_OPTION_VALUE_PREFIX,
} from '../../../constants';
import { InputFieldType } from '../../../models/enums/input-field-type';
import { createAttributeObject } from '../../../utils/building.utils';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { TemplateModel } from '../../../models/template-model';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { MasterValue } from '../../../models/masters/master-value';

interface EditRailingProps {
    data?: { building: Building; editRailing: UnitRailing };
    onClose?: (status: SubmitStatus, data: any) => any;
}

export const EditRailing = ({ data: { building, editRailing }, onClose }: EditRailingProps) => {
    const { isLoading, result, isError } = useLoadData<MasterType[]>(`masters?type=railing`);

    const title = editRailing.railingID ? 'Edit RAILING' : 'ADD NEW RAILING';
    const [templates, setTemplates] = useState<TemplateModel[]>([]);
    const [selectedTemplate, setSelectedTemplate] = useState<TemplateModel | null>(null);
    const [initialData, setInitialData] = useState<any>();
    useEffect(() => {


        if (result) {
            setInitialData({
                railingID: editRailing.railingID,
                railingName: editRailing?.railingName,
                direction: editRailing?.direction,
                railingNotes: editRailing?.notes,
                hasDamage: editRailing?.hasDamage,
                isActive: editRailing?.isActive,
                ...(result && { ...createAttributeObject(result, editRailing.attributes) }),
            });
            console.log(initialData);
        }
    }, [editRailing, result]);
    useEffect(() => {
        if (building !== null && building) {
            axios.get(`${WINDOWS_DOORS_API_URL}/api/TemplateAsset/GetGetTemplateAssetsByEntityType/${building.buildingID}/railing`)
                .then(response => {
                    setTemplates(response.data);

                    if (response.data) {
                        let templates: TemplateModel[] = response.data as TemplateModel[];
                        if (templates.length > 0) {
                            let defaultTemplate = templates.filter(e => e.templateDefaultID > 0);
                            if (!isEmpty(defaultTemplate) && !editRailing.railingID) {
                                setSelectedTemplate(defaultTemplate[0]);
                            }
                        }
                    }
                })
                .catch(error => {
                    console.error("Error loading templates:", error);
                });
        }
    }, [building?.buildingID]);

    useEffect(() => {
        // Update initialData based on selectedTemplate and result
        if (selectedTemplate && result) {
            const newInitialData = {
                observations: editRailing.observations,
                images: editRailing.images,
                notes: selectedTemplate.notes,
                railingID: editRailing.railingID,
                roomID: editRailing.roomID,
                imageCordinateID: editRailing.imageCordinateID,
                railingName: editRailing.railingName,
                direction: selectedTemplate.direction ?? '',
                hasDamage: selectedTemplate.hasDamaged,
                isActive: editRailing?.isActive,
                ...createAttributeObject(result, selectedTemplate?.attributes?.map(e => { return { attributeName: e.attributeName, attributeValue: e.attributeValue, attributeid: e.attributeId } }), true),
            };
            setInitialData(newInitialData);
        }
    }, [selectedTemplate, result]);


    const postDataFormatter = (data: any) => {
        editRailing.railingName = data.railingName;
        editRailing.direction = data.direction;
        editRailing.notes = data.railingNotes;
        editRailing.hasDamage = data.hasDamage;
        editRailing.isActive = true;
        if (editRailing.roomID === 0) {
            editRailing.roomID = Number(data.roomID);
        }
        editRailing.attributes = result.map((item) => {
            return { attributeName: item.name, attributeValue: data[item.name] };
        });
        editRailing.attributes = chain(result)
            .map((item) => {
                return {
                    attributeName: item.name,
                    attributeValue: getAttributeValue(item, data),
                };
            })
            .filter((a) => !!a.attributeValue)
            .unionBy((a) => a.attributeName)
            .value();
        return editRailing;
    };

    const getAttributeValue = (item: any, data: any) => {
        if (item.inputType === InputFieldType.MultiSelect) {
            let customOption = '';
            const selectedItems = item.values.filter((v: MasterValue) => {
                if (v.value.toLowerCase() !== WINDOWS_DOOR_OTHER_OPTION.toLowerCase()) {
                    return data[v.value];
                }
                else {
                    if (data[`${v.value}-${v.id}`] === true && data[`${v.value}-${v.id}`] !== "" && data[`${v.value}value-${v.id}`] !== undefined && data[`${v.value}value-${v.id}`] !== '') {
                        customOption = data[`${v.value}value-${v.id}`];

                        return true;
                    }
                }
            }
            );
            console.log(selectedItems);
            let option = join(map(selectedItems, (s) => s.value), ',');
            if (customOption !== '') {
                option = option + ":" + customOption;
            }
            console.log(option);

            return option;
        } else {
            const value = data[item.name];
            return value &&
                item.inputType === InputFieldType.SingleSelect &&
                value.toLowerCase() === WINDOWS_DOOR_OTHER_OPTION.toLowerCase()
                ? data[item.name + WINDOWS_DOOR_OTHER_OPTION_VALUE_PREFIX]
                : value;
        }
    };

    const validationSchema = Yup.object().shape({
        railingName: Yup.string().required('Required'),
    });

    const handleTemplateChange = (e: DropDownListChangeEvent) => {

        setSelectedTemplate((prev) => e.target.value as TemplateModel || null);
    };

    return (
        <>
            <FormikModelDialog
                width='70%'
                title={title}
                show={!!editRailing}
                initialData={initialData}
                validationSchema={validationSchema}
                postRoute="railings/railing"
                postDataFormatter={postDataFormatter}
                onClose={onClose}
            >
                <>

                    <LoadingIndicator isLoading={isLoading} />
                    {result && (
                        <>

                            <EditRailingForm
                                onTemplateChange={handleTemplateChange}
                                selectedTemplate={selectedTemplate}
                                templates={templates}
                                attributes={result}
                                building={building}
                                railing={editRailing}
                            />

                        </>
                    )}
                </>
            </FormikModelDialog>
        </>
    );
};
