import { MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';

export interface CrumbItem {
    name: string;
    route?: string;
    onClick?: MouseEventHandler<HTMLAnchorElement>;

}
interface BreadcrumbProp {
    crumbs: CrumbItem[];
}

export const Breadcrumbs = ({ crumbs }: BreadcrumbProp) => {
    if (crumbs.length <= 1) {
        return null;
    }
    return (
        <div className="bread-curmb" >
            {crumbs.map(({ name, route, onClick }, key) =>
                key + 1 === crumbs.length || !route ? (
                    <span key={key}><a href="#" onClick={onClick}>{name}</a></span>
                ) : (
                    <Link key={key} to={route}>
                        {name}
                    </Link>
                ),
            )}
        </div>
    );
};
