
import { useEffect, useRef, useState } from 'react';
import { FormikModelDialog, SubmitStatus } from '../../../components/formik-model-dialog.component';
import * as Yup from 'yup';
import { ClaimObservation } from '../../../models/claim/claim-observation';
import { Field } from 'formik';
import { ClaimObservationOption } from '../../../models/claim/claim-observation-option';
import { ClaimObservationOptionNote } from '../../../models/claim/claim-observation-note';
import { InputTextArea } from '../../../components/form/input-textarea.component';

interface IEntityNoteProps {
    claimObservationNote: ClaimObservationOptionNote;
    onClose?: (status: SubmitStatus, data: any) => any;
}

export const EntityNote: React.FunctionComponent<IEntityNoteProps> = (props) => {

    const { claimObservationNote, onClose } = props;
    const [initialData, setInitialData] = useState<ClaimObservationOptionNote>(claimObservationNote);
    const title = claimObservationNote && claimObservationNote?.note !== "" ? "Edit  Note" : "Add Note";

    useEffect(() => {
        console.log(claimObservationNote)
    }, [claimObservationNote]);
    const validationSchema = Yup.object().shape({
        note: Yup.string().required('Note cannot be empty!!')
            .max(500, 'Max length must be 500 character')
    });

    return (
        <>
            <FormikModelDialog
                width='40%'
                title={title}
                show={!!claimObservationNote}
                initialData={initialData}
                validationSchema={validationSchema}
                postRoute="ClaimObservation/SaveClaimObservationOptionNote"
                onClose={onClose}
            >
                <>
                    <div className="col-12 justify-content-end">
                        {/* //<Field label={"Note"} as={"textarea"} name={"note"} length={10} defaultValue={claimObservationNote?.note} className="col-12" rows={3} cols={40} ></Field> */}
                        <InputTextArea label={"Note"} name={"note"}></InputTextArea>
                    </div>
                </>
            </FormikModelDialog ></>)

}