import React from "react";
import FileDialogButton from "../../../components/add-image.button.component";
import ImageUploader from "../../../components/add-images.component";
import { DisplayImages } from "../../../components/display-images";
import { GuardedComponent } from "../../../components/guarded.componemt";
import { ObservationType } from "../../../models/masters/observation-type";
import { PermissionName } from "../../../models/user-security/permission.name";
import { deleteFile, editComments, fetchFile, ImgFile, uploadImages } from "../../../utils/uploadfile.util";
import { fetchFiles } from "../../../api-client.ts/fileapi";
import { AsyncThunkAction } from "@reduxjs/toolkit";
import { AsyncThunkConfig } from "@reduxjs/toolkit/dist/createAsyncThunk";
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import { UploadedFile, FetchFilesParams } from "../../../redux/reducers/filereducer";
import { EntityType } from "../../../models/claim/entity-type";
import { DisplayImagesInCard } from "../../../components/display-images-card";

interface IEntityGallleryProps {
    roomID: number;
    entityID: number;
    entityType: ObservationType;
    entityName?: string;
    claimGuID?: string;

}


export const EntityGallery: React.FunctionComponent<IEntityGallleryProps> = (props) => {
    const { entityID, entityType, entityName, roomID, claimGuID } = props;

    const [imgpreview, showImgPreview] = React.useState<{
        imgpreview: boolean;
        src: File;
        comment?: string;
    }>({
        imgpreview: false,
        src: null,
        comment: '',
    });
    const [entityGalleryPhoto, setEntityGalleryPhoto] = React.useState<ImgFile[]>([]);
    const [EntityGalleryPhotoData, setEntityGalleryPhotoData] = React.useState<{
        entityType: EntityType;
        entityId: number;
    }>();
    const [linkImageData, setLinkImageData] = React.useState<{
        entityType: EntityType;
        entityId: number;
        entityName: string;
        direction: string;
    }>();
    const uploadedfileList = React.useRef<UploadedFile[]>([]);
    const dispatch = useDispatch<AppDispatch>();


    React.useEffect(() => {
        loadImages();
    }, []);

    // const openGallery = (data: { entityType: EntityType; entityId: number }) => {
    //     setImageListData(data);
    // };
    const handleAddFileToImgList = async (file: File) => {
        await uploadImages([file], {
            claimGuID: claimGuID,
            EntityID: entityID.toString(),
            EntityType: entityType,
            IsInterior: false,
            Comments: imgpreview.comment.toString(),
        }).then((value: UploadedFile) => {
            loadImages();
            entityGalleryPhoto.push({
                comment: imgpreview.comment!,
                file: file,
                isbaseImg: value.isBase,
                baseID: 0,
                entityID: value.entityID,
                entityType: value.entityType,
                fileID: value.fileID.toString(),
                userGuid: '',
            });
            //alert('upload success');

            showImgPreview({
                imgpreview: false,
                src: null,
            });
        });
    };

    const loadImages = async () => {
        try {
            //setPhoto([]);
            const action = await dispatch(
                fetchFiles({
                    FileType: 'image',
                    UserID: 0,
                    EntityType: entityType,
                    EntityID: entityID,
                }),
            );

            if (action.payload) {
                let uploadedFileArray = action.payload as UploadedFile[];

                uploadedfileList.current = uploadedFileArray;

                let files = await Promise.all(
                    uploadedFileArray.map(async (e) => {
                        return fetchFile(
                            e.fileCDN,
                            e.comments,
                            e.entityID.toString(),
                            entityType,
                            e.isBasePhoto,
                            e.fileID.toString(),
                            e.basePhotoID
                        );
                    }),
                );

                setEntityGalleryPhoto(files);
            } else {
                setEntityGalleryPhoto([]);
            }
        } catch (error) {
            console.error('Error fetching files:', error);
            setEntityGalleryPhoto([]);
        }
    };

    return (<>
        <GuardedComponent permission={PermissionName.ViewObservation}>
            {imgpreview.imgpreview ? (
                <ImageUploader
                    onAddImg={(comment: string) => {
                        imgpreview.comment = comment;
                        handleAddFileToImgList(imgpreview.src);
                    }}
                    onclose={() => {
                        showImgPreview({
                            imgpreview: true,
                            src: null,
                        });
                    }}
                    showModal={imgpreview.imgpreview}
                    img={imgpreview.src}
                />
            ) : null}
            <div className="bg_white_stack m-2" >
                <div className="stack_title">
                    <h4>Gallery</h4>{' '}
                    {entityType && (<div>

                        <FileDialogButton
                            handleImgSelect={(e) => {
                                e.preventDefault();
                                //handleAddFileToImgList(e.target.files[0])
                                showImgPreview({
                                    imgpreview: true,
                                    src: e.target.files[0],
                                });
                            }}
                        ></FileDialogButton>{' '}

                    </div>)}
                </div>
                <div className="photoGal-wrap">
                    <DisplayImages
                        handleCancel={async () => {
                            await loadImages();
                        }}
                        handleDelete={async (img: ImgFile) => {
                            const isdeleted: boolean = await deleteFile(img);
                            if (isdeleted) {
                                loadImages();
                            }
                        }}
                        handlesave={async (file: ImgFile) => {
                            //handleSave(img);
                            const isedited = await editComments({
                                fileId: file.fileID!,
                                comments: file.comment,
                                isBaseImg: file.isbaseImg,
                                baseID: file.baseID,
                                entityID: file.entityID,
                                entityType: file.entityType,
                                fileID: file.fileID,
                                userGuid: '',

                            });
                            if (isedited) {
                                loadImages();
                            }
                        }}
                        imgList={entityGalleryPhoto ? entityGalleryPhoto : []}
                        noImagesMessage={'<p>There are no photos associated with this asset. </br>Click <strong>Add Photo</strong> button to add photo.</p>'}
                    />
                </div>
            </div>
        </GuardedComponent>
    </>)
};

