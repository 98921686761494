import { ErrorMessage, useField, useFormikContext } from 'formik';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { isEmpty, isNil } from 'lodash';

interface DatePickerFieldProps {
    name: string;
    label?: string;
    startDate?: Date;
}

export const DatePickerField = ({
    name,
    label,
    startDate = new Date(1990, 0, 1), // Default start date: January 1, 1990
}: DatePickerFieldProps): JSX.Element => {
    const [{ value }, meta, { setValue }] = useField<Date>(name);
    const { setFieldValue } = useFormikContext();

    // Ensure `startDate` is at least January 1, 1990, and `endDate` is today
    const minDate = new Date(1990, 0, 1); // January 1, 1990
    const maxDate = new Date(); // Today's date

    return (
        <div className='col'>
            {label && <label className="label">{label}</label>}
            <DatePicker
                selected={value || null}
                onChange={(date: Date) => {
                    setValue(date);
                    setFieldValue(name, date);
                }}
                minDate={minDate}
                maxDate={maxDate}
                dateFormat="MM/dd/yyyy"
                className="form-control search-right-align calendarBg"
            />
            <small className="errorMsg">
                <ErrorMessage name={name} />
            </small>
        </div>
    );
};
