import React from 'react';
interface FileDialogButtonProps {
    handleImgSelect?: React.ChangeEventHandler<HTMLInputElement>;
    ispopup?: boolean;
    fileInputID?: string;
}

const FileDialogButton: React.FC<FileDialogButtonProps> = ({ ...props }) => {
    const { handleImgSelect, ispopup = false, fileInputID } = props;
    const fileInputName = fileInputID && fileInputID !== "" ? fileInputID : "fileInput";
    console.log(fileInputName)
    return (
        <div>
            <button
                className="btn btn-outline-primary btn-sm"
                onClick={(e) => {
                    e.preventDefault();

                    !ispopup
                        ? document.getElementById(fileInputName).click()
                        : document.getElementById(fileInputName).click();
                }}
            >
                Add Photo
            </button>
            <input
                id={!ispopup ? 'fileInput' : 'popupFileInput'}
                type="file"
                accept="image/jpeg, image/png"
                style={{ display: 'none' }}
                onChange={(e) => {
                    e.preventDefault();
                    const file = e.target.files[0];
                    if (file) {
                        const reader = new FileReader();
                        reader.onload = (event) => {
                            //  setSelectedImage(event.target.result);
                        };
                        reader.readAsDataURL(file);
                    }
                    console.log(handleImgSelect);

                    handleImgSelect(e);
                }}
            />
        </div >
    );
};

export default FileDialogButton;
