export const addOrUpdateArrayItem = (data: any[], item: any, key: string) => {
  const newData = data ? [...data] : [];
  const index = data?.findIndex((d) => d[key] === item[key]);
  console.log("the index is" + index);
  if (index > -1) {
    newData.splice(index, 1, item);
  } else {
    newData.push(item);
  }
  return newData;
};

export const deleteArrayItem = (data: any[], item: any, key: string) => {
  const newData = data ? [...data] : [];
  const index = data?.findIndex((d) => d[key] === item[key]);
  if (index > -1) {
    newData.splice(index, 1);
  }
  return newData;
};

export function removeDuplicates(array: any[], key: string) {
  const seen: any = {};
  if (array && array.length > 0) {
    return array.filter((item) => {
      const value = item[key];
      if (seen[value]) {
        return false;
      }
      seen[value] = true;
      return true;
    });
  }



}
export function compareStrings(a: string, b: string) {
  // Assuming you want case-insensitive comparison
  a = a.toLowerCase();
  b = b.toLowerCase();

  return (a < b) ? -1 : (a > b) ? 1 : 0;
}