import { ErrorMessage, Field } from 'formik';

interface InputTextAreaProps {
    label: string;
    name: string;
    type?: string;
}

export const InputTextArea = ({ name, type, label }: InputTextAreaProps): any => {
    return (
        <div >
            <label className="label">{label}</label>
            <Field as="textarea" style={{ height: "145px" }} name={name} className="form-control  mb-3" maxRows={40} minRows={30} />
            <small className="errorMsg">
                {' '}
                <ErrorMessage name={name} />
            </small>
        </div>
    );
};