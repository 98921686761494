import React, { useEffect, useRef, useState, DataHTMLAttributes } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { ImgFile, setbasePhoto } from '../utils/uploadfile.util';
import { Checkbox, TextArea } from '@progress/kendo-react-inputs';
import StarIcon from '../images/star-image';
import { LoadingIndicator } from './loading-indicator.component';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface DisplayImgProps {
    imgList: ImgFile[];
    handlesave: Function;
    handleDelete: Function;
    handleCancel?: Function;
    noImagesMessage?: string
}

export const DisplayImages: React.FC<DisplayImgProps> = ({ imgList, handlesave, handleDelete, handleCancel, noImagesMessage }) => {
    const [selectedImage, setSelectedImage] = useState<ImgFile | null>(null);
    const caption = useRef<string>('');
    const [deleteimg, setdeleteImg] = useState<boolean>(false);
    const [isbaseImg, setisbaseImg] = useState<boolean>();
    const [isLoadingImages, setIsLoadingImages] = useState<boolean>(true);


    useEffect(() => {
        setisbaseImg(isbaseImg);
    }, [isbaseImg]);

    useEffect(() => {
        setIsLoadingImages(true); // Set loading to true when imgListState changes
        setTimeout(() => {
            setIsLoadingImages(false); // Simulate loading completion after 2 seconds
        }, 2000);
    }, [imgList]);

    const handleImageClick = (index: number) => {
        setSelectedImage(imgList[index]);
        caption.current = imgList[index].comment;
        if (imgList[index].isbaseImg) {
            setisbaseImg(true);
            console.log(imgList[index].baseID);
        } else {
            setisbaseImg(false);
        }
    };

    const handleCloseDialog = () => {
        setSelectedImage(null);
        caption.current = '';
        handleCancel && handleCancel();
    };

    const handleSaveCaption = () => {
        if (selectedImage) {
            selectedImage.comment = caption.current;
            handlesave(selectedImage);

            if (handleCancel) {
                handleCloseDialog();
            }
        }
    };

    const handleDeleteCaption = () => {
        if (selectedImage) {
            handleDelete(selectedImage);

            setdeleteImg(false);
            handleCloseDialog();
        }
    };

    return (
        <div className="d-flex colomgap-image">
            {isLoadingImages && (<LoadingIndicator isLoading message='Loading Photo(s)..' />

            )}

            {!isLoadingImages && imgList.map((img, index) => (
                <div key={index} style={{ position: 'relative' }}>
                    <div
                        style={{ position: 'absolute', top: 0, left: 20, zIndex: 1 }}
                    >
                        {img.isbaseImg && <StarIcon />}
                    </div>
                    {img?.comment !== "" ? (<>
                        <OverlayTrigger
                            delay={{ hide: 450, show: 300 }}
                            overlay={(props) => (
                                <Tooltip {...props}>
                                    {img?.comment}
                                </Tooltip>
                            )}
                            placement="top">
                            <img
                                key={index}
                                src={URL.createObjectURL(img?.file)}
                                alt={img.file.name}
                                style={{
                                    width: '150px',
                                    height: '150px',
                                    objectFit: 'cover',
                                    cursor: 'pointer',
                                    borderRadius: '5px'
                                }}
                                onClick={() => handleImageClick(index)}
                            />
                        </OverlayTrigger>
                    </>) : (
                        <img
                            key={index}
                            src={URL.createObjectURL(img?.file)}
                            alt={img.file.name}
                            style={{
                                width: '150px',
                                height: '150px',
                                objectFit: 'cover',
                                cursor: 'pointer',
                                borderRadius: '5px'
                            }}
                            onClick={() => handleImageClick(index)}
                        />
                    )}
                </div>
            ))}
            {!isLoadingImages && imgList.length === 0 ? (

                <div className='alertDesign' style={{ width: "100%" }}>
                    <div>
                        <div className='images'></div>
                        <div style={{ textAlign: 'center', margin: "10px" }}>
                            {noImagesMessage && noImagesMessage !== "" ? (<div dangerouslySetInnerHTML={{ __html: noImagesMessage }} />) :
                                (<>There are no photos associated with this observation.<br></br> Click <b>Add Photo</b> button to add photo</>)}
                        </div>
                    </div>
                </div>
            ) : ("")
            }
            {
                selectedImage && (
                    <Dialog title="Image Preview" onClose={handleCloseDialog}>
                        <div style={{ textAlign: 'center', maxHeight: '400px', overflow: 'auto' }} >
                            <img
                                key={selectedImage.fileID}
                                src={URL.createObjectURL(selectedImage.file)}
                                alt={selectedImage.file.name}
                                style={{ width: "auto", }}
                            />
                        </div>
                        <div className="mt-4">

                            <TextArea placeholder='Comment'
                                defaultValue={caption.current}
                                onChange={(e) => {
                                    caption.current = e.target.value;
                                }}
                                name="comment"
                                style={{ width: '100%', minHeight: '75px' }}
                            />
                        </div>

                        <DialogActionsBar>
                            <div className="d-flex p-3">
                                <button
                                    className="btn btn-outline-secondary me-2 btn-sm"
                                    onClick={handleCloseDialog}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="btn btn-secondary  me-auto btn-sm"
                                    onClick={() => {
                                        setdeleteImg(true);
                                    }}
                                >
                                    Delete
                                </button>
                                <div className='d-flex justify-content-start me-4 mt-2'>
                                    <Checkbox
                                        value={isbaseImg}
                                        onChange={async (e) => {
                                            setisbaseImg(e.target.value as boolean);
                                            await setbasePhoto({
                                                comments: caption.current,
                                                fileId: selectedImage.fileID,
                                                baseID: selectedImage.baseID,
                                                entityID: selectedImage.entityID,
                                                entityType: selectedImage.entityType,
                                                isBaseImg: e.target.value as boolean,
                                                fileID: selectedImage.fileID,
                                                userGuid: '',
                                                isInterior: selectedImage.isInterior
                                            });
                                        }}
                                        name="isBaseImg"
                                        style={{ width: '20px', height: '20px', borderColor: "gray" }}
                                    />
                                    <label htmlFor="isBaseImg" style={{ marginLeft: "5px" }}>Mark as Base Image</label>

                                </div>

                                <button className="btn btn-primary btn-sm" onClick={handleSaveCaption}>
                                    Update
                                </button>
                            </div>
                        </DialogActionsBar>
                    </Dialog>
                )
            }

            {
                deleteimg && (
                    <Dialog title={'Delete Image'}>
                        This photo could be linked with multiple observations. Are you sure you want to delete this?
                        <DialogActionsBar>
                            <div className="d-flex margin-fix mt-3 p-3">
                                <button
                                    className="btn btn-secondary btn-sm"
                                    onClick={() => {
                                        setdeleteImg(false);
                                    }}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="btn btn-primary ms-auto btn-sm"
                                    onClick={handleDeleteCaption}
                                >
                                    OK
                                </button>
                            </div>
                        </DialogActionsBar>
                    </Dialog>
                )
            }
        </div >
    );
};
