import { useState, useEffect } from 'react';
import { ObservationComponent } from './observation.component';
import { ClaimObservation } from '../../../models/claim/claim-observation';
import { ImgFile } from '../../../utils/uploadfile.util';
import { Gallery, Image } from 'react-grid-gallery';
import { DisplayImages } from '../../../components/display-images';
import FileDialogButton from '../../../components/add-image.button.component';
import { PermissionName } from '../../../models/user-security/permission.name';
import { GuardedComponent } from '../../../components/guarded.componemt';
import { ObservationType } from '../../../models/masters/observation-type';

interface ObservationListComponentProps {
    observations: ClaimObservation[];
    entityType?: ObservationType;
    imgList?: ImgFile[];
    onEditObservation?: any;
    onDeleteObservation?: any;
    onOpenGallery?: any;
    onCopyObservation?: (observation: ClaimObservation) => any;
    onShowObservationPhotos?: any;
    photos?: Image[];
    onAddNewObservationClick?: Function;
    oncopyobservation?: Function;
    ispopup?: boolean;
    addphoto?: Function;
    handleDelete?: Function;
    handleSave?: Function;
    handleCancel?: Function;
    isimgclaim?: boolean;
}

export const ObservationListComponent: React.FC<ObservationListComponentProps> = ({
    observations,
    handleCancel,
    handleDelete,
    handleSave,
    onEditObservation,
    addphoto,
    onDeleteObservation,
    onOpenGallery,
    onAddNewObservationClick,
    oncopyobservation,
    ispopup = false,
    imgList,
    onCopyObservation,
    isimgclaim = false,
    onShowObservationPhotos,
    entityType,
    photos,

}: ObservationListComponentProps) => {
    const [selectedState, changeSelectedState] = useState<{ activeObservation: ClaimObservation, observations: ClaimObservation[] }>({
        activeObservation: null,
        observations: [],
    });


    useEffect(() => {
        changeSelectedState({ activeObservation: null, observations: observations });
    }, [observations]);

    const toggleActive = (index: number) => {
        changeSelectedState({
            ...selectedState,
            activeObservation: selectedState.observations[index],
        });
    };
    const toggleactivestyle = (index: number) => {
        //alert('DF');
        if (selectedState.observations[index] === selectedState.activeObservation) {
            return 'boservation-grid active';
        } else {
            return 'boservation-grid';
        }
    };

    return (
        <div>
            <div className="bg_white_stack">
                <div className="stack_title">
                    <h4>Observations</h4>{' '}
                    {!ispopup && (
                        <div className='row'>
                            <div className='d-flex justify-content-end m-1'>
                                <div className='m-1'>
                                    <GuardedComponent permission={PermissionName.AddObservation}>
                                        <button
                                            className="btn btn-outline-primary btn-sm"
                                            onClick={() => {
                                                onAddNewObservationClick();
                                            }}
                                        >
                                            Add Observation
                                        </button>
                                    </GuardedComponent>
                                </div>


                                {/* <GuardedComponent permission={PermissionName.AddObservation}>
                                <button
                                    className="btn btn-outline-primary btn-sm ms-2"
                                    onClick={() => {
                                        oncopyobservation();
                                    }}
                                >
                                    Copy From Other Observation
                                </button>
                            </GuardedComponent> */}
                                {!ispopup && !entityType && (<div className='m-1'>
                                    <GuardedComponent permission={PermissionName.AddObservation}>
                                        <FileDialogButton
                                            handleImgSelect={(e) => {
                                                addphoto(e);

                                            }}
                                        ></FileDialogButton>{' '}
                                    </GuardedComponent>
                                </div>)}
                            </div>
                        </div>
                    )}
                </div>
                <div className="observation_2col">
                    <div>
                        {observations?.length > 0 ? (observations &&
                            observations?.length > 0 &&
                            selectedState.observations?.map((item, index) => (
                                <ObservationComponent
                                    className={toggleactivestyle(index)}
                                    onselect={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                                        toggleActive(index);
                                    }}
                                    key={`Observation_${item.observationID}`}
                                    observation={item}
                                    onEditObservation={onEditObservation}
                                    onDeleteObservation={onDeleteObservation}
                                    onOpenGallery={onOpenGallery}
                                    onCopyObservation={onCopyObservation}
                                    onShowObservationPhotos={onShowObservationPhotos}
                                />
                            ))) : <div className='alertDesign'>
                            <center><div>
                                <div className={!isimgclaim ? 'images vw-100' : 'vw-50 images'}></div>



                                <div>
                                    <b>
                                        There are no observations at this level. Please click the Add Observation button to add an observation
                                    </b>
                                    <br />
                                    {/* <div style={{ textAlign: 'center' }}> <b>Button:Add Observation</b></div> */}
                                </div>
                            </div></center>
                        </div>

                        }
                    </div>

                    {observations?.length > 0 ? <div>
                        {photos && photos?.length > 0 ? (
                            <div>

                                <Gallery
                                    thumbnailStyle={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                    images={photos.filter((e) => e.src !== '')}
                                    enableImageSelection={false}
                                />
                            </div>
                        ) : (
                            <div className='alertDesign'>
                                <div>
                                    <div className='images'></div>
                                    {selectedState.activeObservation !== null && selectedState.activeObservation.photos === undefined ? (<div style={{ textAlign: 'center' }}><b>There are no photos associated with this observation. Click Edit to add photos</b><br></br>{/* <b>Button: Edit Observation</b> */}</div>) : (
                                        <div><b>Select an observation from the left section to view associated photos</b></div>)}
                                </div>
                            </div>
                        )}
                    </div> : null
                    }
                </div>
            </div>
            <div className="bg_white_stack">
                <div className="stack_title">
                    <h4>Photos</h4>{' '}
                    {!ispopup && entityType && (<div>
                        <GuardedComponent permission={PermissionName.AddObservation}>
                            <FileDialogButton
                                handleImgSelect={(e) => {
                                    addphoto(e);
                                }}
                            ></FileDialogButton>{' '}
                        </GuardedComponent>
                    </div>)}
                </div>
                <div className="photoGal-wrap">

                    <DisplayImages

                        handleCancel={() => handleCancel()}
                        handleDelete={(img: ImgFile) => {
                            handleDelete(img);
                        }}
                        handlesave={(img: ImgFile) => {
                            handleSave(img);
                        }}
                        imgList={imgList ? imgList : []}
                    />


                </div>
            </div>
        </div >
    );
};
